import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Button, InputField, Label, Select, View } from '@peakon/components';

import {
  KINETAR_CONFIG_CONSTANTS,
  KINETAR_PLAN_CONSTANTS,
  KINETAR_TARGET_USER_TYPE_CONSTANTS,
} from '../../../constants/KinetarConstants';
import useApi from '../../../hooks/useApi';
import { slugify } from '../../../utils/urlUtil';

import styles from './styles.css';

const KINETAR_CONFIG_SELECT_OPTIONS = KINETAR_CONFIG_CONSTANTS.map((key) => ({
  value: key,
  label: key,
}));

const KINETAR_PLAN_SELECT_OPTIONS = KINETAR_PLAN_CONSTANTS.map((key) => ({
  value: key,
  label: key,
}));

const KINETAR_TARGET_USER_TYPE_SELECT_OPTIONS =
  KINETAR_TARGET_USER_TYPE_CONSTANTS.map((key) => ({
    value: key,
    label: key,
  }));

const GMS_OVERRIDE_EMPLOYEE_COUNT = 491;
const DEMOCOMMENTS_OVERRIDE_EMPLOYEE_COUNT = 300;
const GMS_DEFAULT_PLAN = 'premier';
const DEFAULT_EMPLOYEE_COUNT = 500;
const DEFAULT_WEEKS = 68;
const DEFAULT_WEEKS_AND_EMPLOYEES_MULTIPLICATION_LIMIT = 68_000;

AddTestCompanyComponent.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  loading: PropTypes.bool,
};

export default function AddTestCompanyComponent({
  onSuccess,
  onError,
  loading,
}) {
  const [postNewTestCompany] = useApi({
    method: 'POST',
  });

  const [newTestCompany, setNewTestCompany] = useState({
    name: '',
    domain: '',
    weeks: DEFAULT_WEEKS,
    employees: DEFAULT_EMPLOYEE_COUNT,
    configName: null,
    plan: null,
    email: '',
    targetUserType: null,
    salesforceId: '',
  });

  const hasValidNewTestCompany =
    /^Kinetar .+/.test(newTestCompany.name) &&
    /^kinetar-.+\.com$/.test(newTestCompany.domain) &&
    newTestCompany.targetUserType != null &&
    (newTestCompany.targetUserType.value !== 'customer' ||
      newTestCompany.salesforceId !== '');

  const isGMSCompany =
    newTestCompany.configName &&
    (newTestCompany.configName.value === 'gms' ||
      newTestCompany.configName.value === 'gmsBeta');

  const isDemoCommentsCompany =
    newTestCompany.configName &&
    newTestCompany.configName.value === 'demoComments';

  async function handleSubmitTestCompany(synthEvent) {
    synthEvent.preventDefault();
    try {
      await submitTestCompany(postNewTestCompany, newTestCompany);
      await onSuccess(newTestCompany.name);
    } catch (error) {
      await onError(error.message);
    }
  }

  function handleTestCompanyChange(changeValue, attribute) {
    let valueObject = { [attribute]: changeValue };
    if (attribute === 'name') {
      valueObject = {
        name: `Kinetar ${changeValue.replace(/kinetar ?/gi, '')}`,
        domain: `kinetar-${slugify(
          changeValue.replace(/kinetar ?/gi, ''),
        )}.com`,
      };
    }

    if (attribute === 'configName' && changeValue.value === 'gms') {
      valueObject = {
        ...valueObject,
        plan: {
          label: 'premier',
          value: 'premier',
        },
      };
    }

    if (
      attribute === 'employees' &&
      changeValue * (newTestCompany.weeks ? newTestCompany.weeks : 1) >
        DEFAULT_WEEKS_AND_EMPLOYEES_MULTIPLICATION_LIMIT
    ) {
      valueObject = {
        ...valueObject,
        weeks: Math.floor(
          DEFAULT_WEEKS_AND_EMPLOYEES_MULTIPLICATION_LIMIT / changeValue,
        ),
      };
    }

    if (
      attribute === 'weeks' &&
      changeValue * (newTestCompany.employees ? newTestCompany.employees : 1) >
        DEFAULT_WEEKS_AND_EMPLOYEES_MULTIPLICATION_LIMIT
    ) {
      valueObject = {
        ...valueObject,
        employees: Math.floor(
          DEFAULT_WEEKS_AND_EMPLOYEES_MULTIPLICATION_LIMIT / changeValue,
        ),
      };
    }
    setNewTestCompany({ ...newTestCompany, ...valueObject });
  }

  function getCompanyAttributes(company) {
    return {
      ...company,
      employees: isDemoCommentsCompany
        ? DEMOCOMMENTS_OVERRIDE_EMPLOYEE_COUNT
        : isGMSCompany
          ? GMS_OVERRIDE_EMPLOYEE_COUNT
          : company.employees,
      email: company.email.trim() === '' ? undefined : company.email,
      configName: company.configName?.value ?? 'demo',
      plan: isGMSCompany ? GMS_DEFAULT_PLAN : company.plan?.value,
      origin: 'backoffice',
      targetUserType: company.targetUserType.value,
      salesforceId:
        company.salesforceId.trim() === '' ? null : company.salesforceId,
    };
  }

  async function submitTestCompany(apiClient, company) {
    if (company.name !== '' && company.domain !== '') {
      await apiClient('/test_companies', null, {
        data: {
          type: 'test_companies',
          attributes: getCompanyAttributes(company),
        },
      });
    }
  }

  return (
    <form
      className={styles.formComponent}
      action=""
      onSubmit={(E) => handleSubmitTestCompany(E)}
    >
      <View className={styles.field}>
        <InputField
          label="Name"
          inputType="text"
          onChange={(e) => handleTestCompanyChange(e, 'name')}
          value={newTestCompany.name}
          placeholder="Kinetar"
        />
      </View>
      <View className={styles.field}>
        <InputField
          label="Domain"
          inputType="text"
          onChange={(e) => handleTestCompanyChange(e, 'domain')}
          value={newTestCompany.domain}
          placeholder="kinetar.com"
        />
      </View>
      <View className={styles.field}>
        <InputField
          label="Employees"
          inputType="number"
          onChange={(e) => handleTestCompanyChange(e, 'employees')}
          value={
            isGMSCompany
              ? GMS_OVERRIDE_EMPLOYEE_COUNT
              : isDemoCommentsCompany
                ? DEMOCOMMENTS_OVERRIDE_EMPLOYEE_COUNT
                : newTestCompany.employees
          }
          disabled={isGMSCompany || isDemoCommentsCompany}
          placeholder={
            isGMSCompany
              ? GMS_OVERRIDE_EMPLOYEE_COUNT.toString()
              : isDemoCommentsCompany
                ? DEMOCOMMENTS_OVERRIDE_EMPLOYEE_COUNT
                : DEFAULT_EMPLOYEE_COUNT.toString()
          }
          max={30_000}
          min={1}
        />
      </View>
      <View className={styles.field}>
        <InputField
          label="Weeks"
          inputType="number"
          onChange={(e) => handleTestCompanyChange(e, 'weeks')}
          value={newTestCompany.weeks}
          placeholder={DEFAULT_WEEKS.toString()}
          max={100}
        />
      </View>
      <View className={styles.field}>
        <Label>Config</Label>
        <Select
          onChange={(e) => handleTestCompanyChange(e, 'configName')}
          value={newTestCompany.configName}
          options={KINETAR_CONFIG_SELECT_OPTIONS}
          placeholder="default"
        />
      </View>
      <View className={styles.field}>
        <Label>Plan</Label>
        <Select
          onChange={(e) => handleTestCompanyChange(e, 'plan')}
          value={newTestCompany.plan}
          options={KINETAR_PLAN_SELECT_OPTIONS}
          isDisabled={isGMSCompany}
          placeholder={isGMSCompany ? GMS_DEFAULT_PLAN : 'default'}
        />
      </View>
      <View className={styles.field}>
        <InputField
          label="Owner Email"
          inputType="text"
          onChange={(e) => handleTestCompanyChange(e, 'email')}
          value={newTestCompany.email}
          placeholder="employee@workday.com"
        />
      </View>
      <View className={styles.field}>
        <Label>Target user type</Label>
        <Select
          onChange={(e) => handleTestCompanyChange(e, 'targetUserType')}
          value={newTestCompany.targetUserType}
          options={KINETAR_TARGET_USER_TYPE_SELECT_OPTIONS}
          placeholder="Please select"
        />
      </View>
      <View className={styles.field}>
        <InputField
          label="Salesforce ID"
          inputType="text"
          onChange={(e) => handleTestCompanyChange(e, 'salesforceId')}
          value={newTestCompany.salesforceId}
          placeholder="Required for customers"
        />
      </View>
      <View className={styles.submit}>
        <Button disabled={loading || !hasValidNewTestCompany}>Create</Button>
      </View>
    </form>
  );
}
