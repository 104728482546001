import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import {
  Button,
  InputField,
  Label,
  Select,
  View,
  Spinner,
} from '@peakon/components';

import {
  KINETAR_CONFIG_CONSTANTS,
  KINETAR_PLAN_CONSTANTS,
} from '../../../constants/KinetarConstants';

import styles from './styles.css';

const KINETAR_CONFIG_SELECT_OPTIONS = KINETAR_CONFIG_CONSTANTS.map((key) => ({
  value: key,
  label: key,
}));

const KINETAR_PLAN_SELECT_OPTIONS = KINETAR_PLAN_CONSTANTS.map((key) => ({
  value: key,
  label: key,
}));

const REQUIRED_GMS_EMPLOYEE_COUNT = 491;
const DEFAULT_EMPLOYEE_COUNT = 30_000;
const DEFAULT_WEEKS_AND_EMPLOYEES_MULTIPLICATION_LIMIT = 68_000;
const GMS_CONFIG_NAME = 'gms';
const GMS_DEFAULT_PLAN = { label: 'premier', value: 'premier' };

const FormField = ({ className, children }) => {
  return <div className={classnames(styles.field, className)}>{children}</div>;
};

FormField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default function TestCompanyForm({ testCompany, onReset, onCancel }) {
  const [localCompany, setLocalCompany] = useState(testCompany);
  useEffect(() => {
    setLocalCompany({
      ...testCompany,
      configName: {
        label: testCompany.configName,
        value: testCompany.configName,
      },
      plan: { label: testCompany.plan, value: testCompany.plan },
      adminPassword: '',
    });
  }, [testCompany]);

  const handleCompanyChange = (attribute) => {
    return (changeObject) => {
      let updateState = { [attribute]: changeObject };
      if (attribute === 'configName' || attribute === 'plan') {
        updateState = {
          [attribute]: { label: changeObject.label, value: changeObject.value },
        };
        if (changeObject.value === GMS_CONFIG_NAME) {
          updateState.employees = REQUIRED_GMS_EMPLOYEE_COUNT;
        }
      }

      if (attribute === 'employees') {
        updateState = {
          [attribute]:
            changeObject > DEFAULT_EMPLOYEE_COUNT
              ? DEFAULT_EMPLOYEE_COUNT
              : changeObject,
        };

        if (
          changeObject * (localCompany.weeks ? localCompany.weeks : 1) >
          DEFAULT_WEEKS_AND_EMPLOYEES_MULTIPLICATION_LIMIT
        ) {
          updateState.weeks = Math.floor(
            DEFAULT_WEEKS_AND_EMPLOYEES_MULTIPLICATION_LIMIT / changeObject,
          );
        }
      }

      if (
        attribute === 'weeks' &&
        changeObject * (localCompany.employees ? localCompany.employees : 1) >
          DEFAULT_WEEKS_AND_EMPLOYEES_MULTIPLICATION_LIMIT
      ) {
        updateState.employees = Math.floor(
          DEFAULT_WEEKS_AND_EMPLOYEES_MULTIPLICATION_LIMIT / changeObject,
        );
      }

      setLocalCompany({
        ...localCompany,
        ...updateState,
      });
    };
  };

  const renderForm = () => {
    return (
      <View className={styles.container}>
        <FormField>
          <InputField
            type="number"
            required
            label="Employees"
            value={
              localCompany.configName.value.toUpperCase() ===
              GMS_CONFIG_NAME.toUpperCase()
                ? REQUIRED_GMS_EMPLOYEE_COUNT
                : localCompany.employees
            }
            disabled={
              localCompany.configName.value.toUpperCase() ===
              GMS_CONFIG_NAME.toUpperCase()
            }
            onChange={handleCompanyChange('employees')}
          />
        </FormField>
        <FormField>
          <InputField
            type="number"
            required
            label="Weeks"
            value={localCompany.weeks}
            max={100}
            disabled={
              localCompany.configName.value.toUpperCase() ===
              GMS_CONFIG_NAME.toUpperCase()
            }
            onChange={handleCompanyChange('weeks')}
          />
        </FormField>
        <FormField>
          <Label>Config</Label>
          <Select
            onChange={handleCompanyChange('configName')}
            value={localCompany.configName}
            options={KINETAR_CONFIG_SELECT_OPTIONS}
            isClearable
            placeholder="default"
          />
        </FormField>
        <FormField>
          <Label>Plan</Label>
          <Select
            onChange={handleCompanyChange('plan')}
            value={
              localCompany.configName.value === 'gms'
                ? GMS_DEFAULT_PLAN
                : localCompany.plan
            }
            options={KINETAR_PLAN_SELECT_OPTIONS}
            isDisabled={localCompany.configName.value === 'gms'}
            isClearable
            placeholder="default"
          />
        </FormField>
        <FormField className={styles.actions}>
          <FormField>
            <Button size="small" onClick={() => onReset(localCompany)}>
              Reset
            </Button>
          </FormField>
        </FormField>
      </View>
    );
  };
  return localCompany.configName.value ? (
    renderForm()
  ) : (
    <View className={styles.container}>
      <Spinner size="small" />
    </View>
  );
}

TestCompanyForm.propTypes = {
  testCompany: PropTypes.object,
  onReset: PropTypes.func,
  onCancel: PropTypes.func,
};
