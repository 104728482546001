/**
 * @param  {object} options
 * @param  {string=} options.subdomain
 * @param  {string} options.pathname
 * @param  {Record<string, string>} options.searchParams
 */
export const getDashboardURL = ({
  subdomain = 'app',
  pathname,
  searchParams,
}) => {
  const hostnameParts = window.location.hostname.split('.');
  let domain, topLevelDomain;

  if (hostnameParts.length === 3) {
    // Assume region-less hostname like backoffice.peakon.com
    domain = hostnameParts[1];
    topLevelDomain = hostnameParts[2];
  } else if (hostnameParts.length === 4) {
    // Assume region-specific hostname like backoffice.us1.peakon.com
    domain = hostnameParts[2];
    topLevelDomain = hostnameParts[3];
  } else {
    throw new Error(
      `Unable to generate dashboard URL from location: ${window.location}`,
    );
  }

  const baseURL = `${window.location.protocol}//${subdomain}.${domain}.${topLevelDomain}`;
  const url = new URL(pathname, baseURL);

  for (const param in searchParams) {
    url.searchParams.set(param, searchParams[param]);
  }

  return url.toString();
};
