export const KINETAR_CONFIG_CONSTANTS = [
  'qa',
  'test',
  'diversity',
  'advancedAccessRights',
  'demo',
  'demoBeta',
  'gms',
  'gmsBeta',
  'demoComments',
  'scalingWithMoreManagers',
];
export const KINETAR_PLAN_CONSTANTS = ['essential', 'business', 'premier'];

export const KINETAR_TARGET_USER_TYPE_CONSTANTS = [
  'internal',
  'partner',
  'customer',
];
